import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/blocks/[id]": [8,[2]],
		"/(app)/bookmark-cloud/[id]": [10,[2]],
		"/(app)/bookmark/[id]": [9,[2]],
		"/(app)/chat": [11,[2]],
		"/(app)/chat/[id]": [12,[2]],
		"/login": [51],
		"/(app)/mutes/[id]": [13,[2]],
		"/(app)/notification": [14,[2]],
		"/(app)/profile": [15,[2]],
		"/(app)/profile/[handle]": [16,[2,3]],
		"/(app)/profile/[handle]/feed": [17,[2,3]],
		"/(app)/profile/[handle]/feed/[id]": [18,[2]],
		"/(app)/profile/[handle]/follower": [20,[2,3]],
		"/(app)/profile/[handle]/follow": [19,[2,3]],
		"/(app)/profile/[handle]/likes": [21,[2,3]],
		"/(app)/profile/[handle]/lists": [22,[2,3]],
		"/(app)/profile/[handle]/lists/[id]": [23,[2]],
		"/(app)/profile/[handle]/media": [24,[2,3]],
		"/(app)/profile/[handle]/post/[id]": [25,[2]],
		"/recovery": [52],
		"/(app)/search": [26,[2,4]],
		"/(app)/search/feeds": [27,[2,4]],
		"/(app)/search/user": [28,[2,4]],
		"/(app)/settings": [29,[2,5]],
		"/(app)/settings/about": [30,[2,5]],
		"/(app)/settings/data": [31,[2,5]],
		"/(app)/settings/design": [32,[2,5]],
		"/(app)/settings/design/embed": [33,[2,5]],
		"/(app)/settings/general": [34,[2,5]],
		"/(app)/settings/invite-codes": [35,[2,5]],
		"/(app)/settings/keyword-mutes": [36,[2,5]],
		"/(app)/settings/lang-filter": [37,[2,5]],
		"/(app)/settings/moderation": [38,[2,5]],
		"/(app)/settings/moderation/chat": [39,[2,5]],
		"/(app)/settings/moderation/labeler": [40,[2,5]],
		"/(app)/settings/moderation/modlist": [41,[2,5]],
		"/(app)/settings/news": [42,[2,5]],
		"/(app)/settings/profiles": [43,[2,5]],
		"/(app)/settings/push-notification": [44,[2,5]],
		"/(app)/settings/timeline": [45,[2,5]],
		"/(app)/settings/timeline/reaction": [46,[2,5]],
		"/(app)/shared": [47,[2]],
		"/(app)/theme-store": [48,[2,6]],
		"/(app)/theme-store/code": [49,[2,6]],
		"/(app)/theme-store/mytheme": [50,[2,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';